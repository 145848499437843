




.accordion-style-one{
    .section-title-accordion{
        h3{
            font-size: 40px;
            color: var(--color-lightn);
        }
    }
    .accordion{
        .accordion-item{
            margin-bottom: 30px;
            .accordion-header{
                font-size: 32px;
                color: var(--color-lightn);
                margin-bottom: 0;
                a{
                    font-size: 22px;
                    transition: var(--transition);
                    &.accordion-button{
                        padding: 34px 30px;
                        position: relative;
                        width: 100%;
                        z-index: 1;
                        display: block;
                        .inner{
                            p{
                                font-size: 16px;
                            }
                        }
                        &::after{
                            content: '';
                            position: absolute;
                            left: 0;
                            top: 0;
                            width: 100%;
                            height: 100%;
                            opacity: .35;
                            background: linear-gradient(
                                145deg
                                , #1e2024, #23272b);
                            z-index: -1;
                            border-radius: 8px;
                            box-shadow: var(--shadow-1);
                        }
                        &::before{
                            position: absolute;
                            content: "\e92e";
                            top: 10px;
                            font-family: 'feather' !important;
                            font-weight: 200;
                            right: 35px;
                            top: 50%;
                            transform: translateY(-50%);
                            transition: var(--transition);
                        }
                        &[aria-expanded="true"]{
                            
                            &::before{
                                content: "\e931";
                                transition: var(--transition);
                            }
                            &::after{
                                border-radius: 8px 8px 0px 0px;
                            }
                        }
                        &.two{
                            &::before{
                                content: "\e9b1";
                            }
                            &[aria-expanded="true"]{
                                
                                &::before{
                                    content: "\e996";
                                }
                                &::after{
                                    border-radius: 8px 8px 0px 0px;
                                }
                            }
                        }
                    }
                }
            }
            .accordion-body{
                .inner{
                    padding: 20px;
                    background: #1e2024;
                    border-radius: 0 0px 8px 8px;
                    p{
                        font-size: 16px;
                    }
                }
            }
        }
    }
}




// Start Typography Area //page ://

.rn-typography-area{
    .wp-block-quote{
        padding: 40px 137px;
        position: relative;
        background: transparent;
        color: var(--color-heading);
        z-index: 1;
        border: 0;
        text-align: center;
        margin: 0;
        padding-top: 50px;
        &::before{
            position: absolute;
            width: 100px;
            height: 87px;
            left: 50%;
            top: -37px;
            background-image: url(../images/icons/quote2.png);
            content: "";
            opacity: 1;
            background-repeat: no-repeat;
            z-index: -1;
            transform: translateX(-50%);
            background-size: 100px;
            opacity: .1;
        }
    }
}


p.has-drop-cap:not(:focus):first-letter {
    float: left;
    font-size: 104px;
    line-height: .78;
    margin: 4px 15px 0 0;
    text-transform: uppercase;
    font-family: georgia,serif;
    font-style: normal;
    font-weight: 400;
    color: var(--color-heading);
    -webkit-font-smoothing: antialiased;
    color: var(--color-lightn);
}

.table-area{
    tbody{
        td{
            border: 1px solid var(--color-tertiary);
            padding: 7px 10px;
            color: var(--color-dark-alt);
            strong{
                color: var(--color-lightn);
                font-weight: 500;
            }
            color: var(--color-body);
        }
    }
    .section-title{
        h2{
            &.title{
                font-size: 40px;
            }
        }
    }
}

.hr-area{
    .section-title{
        h2{
            &.title{
                font-size: 24px;
                margin-top: -10px;
                margin-bottom: 5px;
            }
        }
    }
    hr{
        background-size: 4px 4px;
        height: 1px;
        margin: 0 0 24px;
        border: 0;
        border-top: 1px solid #121214;
    }
}


.hr-dots-area{
    .section-title{
        .title{
            font-size: 40px;
            margin-bottom: 10px;
        }
    }
    .wp-block-separator.is-style-dots::before {
        content: "\00b7 \00b7 \00b7";
        padding-left: 21px;
        color: #121214;
        font-size: 21px;
        font-weight: 700;
        letter-spacing: 21px;
        font-family: georgia,serif;
    }
    .wp-block-separator{
        &.is-style-dots{
            background: 0 0;
            border: none;
            text-align: center;
            max-width: none;
            line-height: 1;
            height: auto;
        }
    }
}


.column-galary{
    .section-title{
        .title{
            font-size: 40px;
        }
    }
}

.blocks-gallery-grid{
    display: flex;
    padding: 0;
    margin: 0 -10px;
    list-style: none;
    margin-bottom: 30px;
    flex-wrap: wrap;
    .blocks-gallery-item{
        flex-basis: 50%;
        padding: 0 10px;
        figure{
            overflow:hidden;
            border-radius: 8px;
            img{
                border-radius: 8px;
                transition: var(--transition);
                &:hover{
                    transform: scale(1.1);
                }
            }
        }
    }
}
.Three-Column-gallery{
    .section-title{
        .title{
            font-size: 40px;
        }
    }
    .columns-3{
       .blocks-gallery-item{
        padding: 0 10px;
        flex-basis: 33.33%;
       } 
    }
}

.large{
    .title{
        font-size: 40px !important;
    }
}

.alignfull {
    margin-left: calc(50% - 50vw);
    margin-right: calc(50% - 50vw);
    max-width: 100vw;
    width: 100vw;
}

.alignwide {
    margin-left: -105px;
    margin-right: -105px;
    max-width: 1400px;
    width: auto;
    .radius-4{
        border-radius: 8px;
    }
}
figcaption {
    text-align: center;
    margin-top: 10px;
    font-size: 13px;
    color: var(--color-dark-alt);
    letter-spacing: 0.5px;
}