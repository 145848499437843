/*---------------------------
    Dropdown Menu  
----------------------------*/
.mainmenu-nav {
    .primary-menu {
        li {
            position: relative;
            .submenu {
                min-width: 230px;
                height: auto;
                position: absolute;
                top: 90%;
                left: 0;
                z-index: 90;
                opacity: 0;
                visibility: hidden;
                text-align: left;
                padding: 12px 0;
                transition: .3s;
                border-radius: 10px;
                background-color: var(--background-color-2);
                border-left: 1px solid #ffffff14;
                border-bottom: 1px solid #ffffff14;
                border-right: 1px solid #ffffff14;
                border-top: 1px solid rgba(255, 255, 255, 0.0784313725);
                li {
                    margin: 0;
                    a {
                        font-weight: 500;
                        padding: 4px 14px;
                        font-size: 13px;
                        font-weight: var(--s-regular);
                        display: block;
                        color: var(--color-lightn);
                        margin: 0 10px;
                        border-radius: 4px;
                        transition: .3s;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        text-transform: inherit;
                        &:hover {
                            color: var(--color-primary);
                            background: #060606b3;
                        }
                    }
                }
            }

            &:hover {
                .submenu {
                    opacity: 1;
                    visibility: visible;
                    top: 100%;
                }
            }


            &.has-droupdown {
                & > a {
                    position: relative;
                    &::after {
                        content: "\e92e";
                        position: absolute;
                        top: 50%;
                        font-family: 'feather' !important;
                        right: -7px;
                        transform: translateY(-50%);
                        @media #{$laptop-device} {
                            right: -11px;
                        }
                    }
                }
            }
        }
    }
}




.popup-mobile-menu {
    .primary-menu {
        .has-droupdown {
            & > a {
                position: relative;
                display: block !important;
                &::after{
                    content: '\e92e';
                    position: absolute;
                    right: 0;
                    top: 50%;
                    transform: translateY(-50%);
                    font-family: 'feather' !important;
                }
                &.open {
                    &::after{
                        content: '\e931';
                        position: absolute;
                        right: 0;
                        top: 50%;
                        transform: translateY(-50%);
                        font-family: 'feather' !important;
                    }
                }
            }
            .submenu {
                padding: 5px 22px;
                max-width: 100%;
                list-style: none;
                display: none;
                background: #21252c;
                border-radius: 6px;
                margin-left: 20px;
                margin-top: 10px;
                li {
                    a {
                        font-size: 14px;
                        &.active {
                            color: var(--color-primary) !important;
                        }
                    }
                }
            }
        }
    }
}



