/*----------------------
    Right Demo  
-----------------------*/
.rn-right-demo {
    width: 40px;
    position: fixed;
    z-index: 99;
    right: 0px;
    top: calc(50% - 55px);

    .demo-button {
        border-radius: 6px 0 0 6px;
        background: linear-gradient(145deg, #1e2024, #23272b);
        box-shadow: 0 0 10px rgba(0,0,0,0.1);
        transition: var(--transition);
        position: relative;
        z-index: 2;
        font-size: 14px;
        border: 0 none;
        width: 100%;
        min-width: 100%;
        padding: 10px 0px 8px;
        font-weight: 400;
        letter-spacing: 1px;
        text-transform: uppercase;
        cursor: pointer;

        &::before {
            content: '';
            position: absolute;
            transition: var(--transition);
            width: 100%;
            height: 100%;
            border-radius: 6px;
            top: 0;
            left: 0;
            background: linear-gradient(to right bottom, #212428, #16181c);
            opacity: 0;
            z-index: -1;
        }

        .text {
            -webkit-writing-mode: vertical-rl;
            writing-mode: vertical-rl;
            text-orientation: upright;
            padding-left: 5px;
            padding-right: 5px;
        }
    }
}

// Demo Modal Popup 
.demo-modal-area {
    width: 100%;
    height: 100%;
    left: 0px;
    top: 0px;
    border: 0px;
    background-color: rgba(33, 36, 40, 1);
    position: fixed;
    z-index: 999;
    overflow: auto;
    opacity: 0;
    visibility: hidden;
    transition: 0.5s;
    transform: translateX(100px);

    .demo-close-btn {
        border: 0 none;
        position: fixed;
        right: 50px;
        top: 50px;
        width: 40px;
        display: inline-block;
        height: 40px;
        font-size: 22px;
        border-radius: 6px;
        background: linear-gradient(145deg, #1e2024, #23272b);
        box-shadow: 0 0 10px rgba(0,0,0,0.1);
        transition: var(--transition);
        z-index: 2;

        &::after {
            content: '';
            position: absolute;
            transition: var(--transition);
            width: 100%;
            height: 100%;
            border-radius: 6px;
            top: 0;
            left: 0;
            background: linear-gradient(to right bottom, #212428, #16181c);
            opacity: 0;
            z-index: -1;
        }

        &:hover {
            color: var(--color-primary);
        }
    }

    .wrapper {
        .rn-modal-inner {
            margin: 70px auto;
            border-radius: 6px;
            padding: 50px;
            background: var(--background-color-1);
            box-shadow: var(--shadow-1);
            position: relative;
            max-width: 1170px;
            width: 90%;

            @media #{$md-layout} {
                padding: 40px 30px;
            }

            @media #{$sm-layout} {
                padding: 40px 30px;
            }

            &::after {
                content: '';
                position: absolute;
                transition: var(--transition);
                width: 100%;
                height: 100%;
                border-radius: 10px;
                top: 0;
                left: 0;
                background: linear-gradient(to right bottom, #212428, #16181c);
                opacity: 0;
                z-index: -1;
            }

            .demo-top {
                .title {
                    margin-bottom: 12px;
                }
                .subtitle {
                    font-size: 16px;
                    margin-bottom: 40px;
                }
            }
        }
    }

    &.open {
        opacity: 1;
        visibility: visible;
        transform: none;
    }
}


// Single Demo Styles

.single-demo {
    &.coming-soon {
        pointer-events: none;
    }

    position: relative;
    background: var(--background-color-1);
    box-shadow: var(--shadow-1);
    padding: 0;
    border-radius: 6px;
    margin-bottom: 50px;
    padding-bottom: 20px;

    .inner {
        &.badge-1{
            position: relative;
            z-index: 1;
            overflow: hidden;
            &::after{
                position: absolute;
                content: 'Hot';
                padding: 3px 10px;
                border-radius: 5px;
                z-index: 2;
                font-size: 14px;
                top: 15px;
                right: 15px;
                background: linear-gradient(to right, #6a67ce, #fc636b) !important;
                color: var(--color-white);
                transition: 0.3s;
            }
        }
        &.badge-2{
            position: relative;
            z-index: 1;
            overflow: hidden;
            &::after{
                position: absolute;
                content: 'New';
                padding: 3px 10px;
                border-radius: 5px;
                z-index: 2;
                font-size: 14px;
                top: 15px;
                right: 15px;
                background: linear-gradient(to right, #6a67ce, #fc636b) !important;
                color: var(--color-white);
                transition: 0.3s;
            }
        }
        .thumbnail {
            border-radius: 6px 6px 0 0;
            a {
                position: relative;
                display: block;
                z-index: 2;

                &::after {
                    position: absolute;
                    content: "";
                    background: rgba(0, 0, 0, 0.5);
                    width: 100%;
                    height: 100%;
                    left: 0;
                    top: 0;
                    border-radius: 8px 8px 0 0;
                    transition: 0.4s;
                    opacity: 0;
                }

                .overlay-content {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    z-index: 2;
                    margin-top: 10px;
                    display: inline-block;
                    transition: 0.4s;
                    .overlay-text {
                        display: inline-block;
                        padding: 8px 12px;
                        background: var(--color-primary);
                        font-size: 14px;
                        border-radius: 6px;
                        opacity: 0;
                        transition: 0.4s;
                        color: var(--color-white);
                    }
                }
            }

            img {
                border-radius: 6px 6px 0 0;
            }
        }

        .title {
            margin-bottom: 0;
            padding-top: 16px;
            padding-bottom: 0;
            font-size: 18px;
            text-align: center;
            letter-spacing: .5px;
            border-top: 1px solid #1a1d23;
            margin-top: 0;
            font-weight: 600;

            a {
                color: var(--color-heading);
                display: block;
                transition: 0.3s;

                &:hover {
                    color: var(--color-primary);
                }
            }
        }
    }

    &:hover {
        .inner {
            .thumbnail {
                a {
                    &::after {
                        opacity: 1;
                    }
                    .overlay-content {
                        margin-top: 0;
                        .overlay-text {
                            opacity: 1;
                            
                        }
                    }
                }
            }
        }
        .badge-1:after {
            transform: scale(0.8);
            right: 0;
            top: 7px;
        }
        .badge-2:after {
            transform: scale(0.8);
            right: 0;
            top: 7px;
        }
    }

    &::after {
        content: '';
        position: absolute;
        transition: var(--transition);
        width: 100%;
        height: 100%;
        border-radius: 6px;
        top: 0;
        left: 0;
        background: linear-gradient(to right bottom, #212428, #16181c);
        opacity: 0;
        z-index: -1;
    }
}


/*-------------------
 Popup Tab  
-----------------------*/
.popuptab-area {
    justify-content: center;
    margin: -20px -10px;
    margin-bottom: 50px;
    border: 0 none;
    li {
        margin: 20px 10px;
        a {
            &.nav-link {
                border-radius: 6px;
                background: linear-gradient(145deg, #1e2024, #23272b);
                box-shadow: var(--shadow-1);
                position: relative;
                z-index: 2;
                font-size: 14px;
                font-weight: 500;
                display: inline-block;
                padding: 15px 35px;
                list-style: none;
                text-decoration: none;
                color: var(--color-white);
                outline: none !important;

                &.active {
                    border-color: transparent !important;
                    color: var(--color-primary);
                    background-color: transparent;
                }

                &::before {
                    content: '';
                    position: absolute;
                    transition: var(--transition);
                    width: 100%;
                    height: 100%;
                    border-radius: 6px;
                    top: 0;
                    left: 0;
                    background: linear-gradient(to right bottom, #212428, #16181c);
                    opacity: 0;
                    z-index: -1;
                    
                }


                &:hover ,
                &.active {
                    outline: none;
                    border-color: transparent !important;

                    &::before {
                        opacity: 1;
                    }
                }
            }
        }
    }
}


/*---------------------------------
    Right Demo Light Version  
----------------------------------*/

.demo-modal-area.white-version .wrapper .rn-modal-inner {
    background: var(--gradient-box-w);
    box-shadow: none;
}

.demo-modal-area.white-version {
    background-color: #212428;
}

.demo-modal-area.white-version .single-demo {
    background: var(--gradient-box-w);
    box-shadow: var(--shadow-white-3);
}

.demo-modal-area.white-version .single-demo .inner .title a {
    color: var(--color-heading-wv);
    transition: var(--transition);
}

.demo-modal-area .popuptab-area li a.nav-link {
    position: relative;
    z-index: 2;
}

.demo-modal-area.white-version .popuptab-area li a.nav-link {
    background: var(--gradient-box-w);
    box-shadow: var(--shadow-white-3);
    color: var(--color-heading-wv);
}

.demo-modal-area.white-version .popuptab-area li a.nav-link.active {
    color: var(--color-primary);
}

.demo-modal-area.white-version .popuptab-area li a.nav-link::before {
    display: none;
}

.demo-modal-area.white-version .popuptab-area li a.nav-link.active::before {
    opacity: 1;
}

.demo-modal-area.white-version .single-demo .inner .title {
    border-top: 1px solid transparent;
}

.demo-modal-area.white-version .wrapper .rn-modal-inner .demo-top .title {
    color: var(--color-heading-wv);
}
.demo-modal-area.white-version .wrapper .rn-modal-inner .demo-top .subtitle {
    color: var(--color-body-white);
}










