:root {
    // themes color
    --color-primary: #ff014f;
    --color-secondary: #f4f5f6;
    --color-tertiary: #0d1013;
    --color-gray: #f6f6f6;
    --color-subtitle: #f9004d;

    // background-color
    --background-color-1: linear-gradient(145deg, #1e2024, #23272b);
    --background-color-2: #212428;

    // box-shadow
    --shadow-1: 10px 10px 19px #1c1e22,
        -10px -10px 19px #262a2e;
    --shadow-2: inset 21px 21px 19px #181a1d, inset -21px -21px 19px #202225;
    --inner-shadow: 1px 4px 2px -3px rgba(0, 0, 0, 0.7) inset, -1px -3px 3px -2px rgba(255, 255, 255, 0.2) inset;

    --shadow-white-3: 5px 5px 15px #D1D9E6, -5px -5px 15px #ffffff;


    // Gradient
    --gradient-box-w: linear-gradient(145deg, #e2e8ec, #ffffff);
    --gradient-red-hover: linear-gradient(145deg, #ff014f, #d11414);


    // typo Color
    --color-heading: #ffffff;
    --color-heading-wv: #1e2125;
    --color-body: #878e99;
    --color-body-white: #3c3e41;
    --color-lightn: #c4cfde;

    // Gery Colors 
    // --color-midgray: #9F9F9F;
    --color-midgray: #878787;
    --color-light: #E4E6EA;
    --color-lighter: #CED0D4;
    --color-lightest: #F0F2F5;
    --color-border: #E6E6E6;
    --color-white: #ffffff;
    --color-white-75: rgba(255, 255, 255, 0.75);


    // notify Colors
    --color-success: #3EB75E;
    --color-danger: #FF0003;
    --color-warning: #FF8F3C;
    --color-info: #1BA2DB;


    //Social icon colors
    --color-facebook: #3B5997;
    --color-twitter: #1BA1F2;
    --color-youtube: #ED4141;
    --color-linkedin: #0077B5;
    --color-pinterest: #E60022;
    --color-instagram: #C231A1;
    --color-vimeo: #00ADEF;
    --color-twitch: #6441A3;
    --color-discord: #7289da;


    // Font weight
    --p-light: 300;
    --p-regular: 400;
    --p-medium: 500;
    --p-semi-bold: 600;
    --p-bold: 700;
    --p-extra-bold: 800;
    --p-black: 900;

    // Font weight
    --s-light: 300;
    --s-regular: 400;
    --s-medium: 500;
    --s-semi-bold: 600;
    --s-bold: 700;
    --s-extra-bold: 800;
    --s-black: 900;


    //transition easing
    --transition: 0.4s;


    // Font Family
    --font-primary: 'Poppins', sans-serif;
    --font-secondary: 'Montserrat', sans-serif;


    //Fonts Size
    --font-size-b1: 18px;
    --font-size-b2: 22px;
    --font-size-b3: 14px;


    //Line Height
    --line-height-b1: 1.50;
    --line-height-b2: 1.6;
    --line-height-b3: 1.7;


    // Heading Font
    --h1: 70px;
    --h2: 60px;
    --h3: 50px;
    --h4: 40px;
    --h5: 30px;
    --h6: 20px;

}

// Layouts Variation
$smlg-device: 'only screen and (max-width: 1199px)';
$extra-device: 'only screen and (min-width: 1600px) and (max-width: 1919px)';
$laptop-device: 'only screen and (min-width: 1200px) and (max-width: 1599px)';
$lg-layout: 'only screen and (min-width: 992px) and (max-width: 1199px)';
$md-layout:'only screen and (min-width: 768px) and (max-width: 991px)';
$sm-layout:'only screen and (max-width: 767px)';
$large-mobile: 'only screen and (max-width: 575px)';
$small-mobile: 'only screen and (max-width: 479px)';