/*---------------------------
    Header Area Styles 
-----------------------------*/

a.rn-btn,
button.rn-btn {
    display: inline-block;
    padding: 15px 35px;
    list-style: none;
    text-decoration: none;
    color: var(--color-primary);
}

a,
button {
    &.rn-btn {
        border-radius: 6px;
        background: linear-gradient(145deg, #1e2024, #23272b);
        box-shadow: var(--shadow-1);
        transition: var(--transition);
        position: relative;
        z-index: 2;
        font-size: 14px;
        font-weight: 500;
        border: 0 none;

        &::before {
            content: '';
            position: absolute;
            transition: var(--transition);
            width: 100%;
            height: 100%;
            border-radius: 6px;
            top: 0;
            left: 0;
            background: linear-gradient(to right bottom, #212428, #16181c);
            opacity: 0;
            z-index: -1;
        }

        &:hover {
            transform: translateY(-5px);

            &::before {
                opacity: 1;
            }
        }

        &.no-shadow {
            box-shadow: none;
        }

        &.btn-theme {
            background: var(--color-primary) !important;
            color: var(--color-white) !important;
            &::before {
                display: none;
            }
            &:hover {

            }
        }

        &.btn-transparent {
            background: transparent;
            &::before {
                display: none;
            }
        }

        &.thumbs-icon {
            svg {
                margin-top: -7px;
            }
        }
    }
    
}


.button-style {
    .main-page-wrapper {
        position: relative;
        top: 122px;
    }
}

.breadcrumb-style-one {
    background: #1f2125;
    padding: 40px 0;

    .breadcrumb-inner {
        display: flex;
        justify-content: space-between;
        align-items: center;

        @media #{$large-mobile} {
            display: block;
            text-align: center;
        }
        .title {
            font-size: 30px;
            color: var(--color-lightn);
            margin: 0;
        }

        .page-list {
            display: flex;
            list-style: none;
            margin: 0;
            padding-left: 0;
            
            @media #{$large-mobile} {
                margin-top: 12px;
                justify-content: center;
            }
            li {
                margin-top: 0;
                margin-bottom: 0;
                font-size: 16px;
                list-style: none;

                a {
                    transition: var(--transition);
                }

                &.separator {
                    position: relative;
                    padding: 0 20px;

                    &::after {
                        position: absolute;
                        background-color: var(--color-primary);
                        width: 5px;
                        content: "";
                        height: 5px;
                        border-radius: 100%;
                        top: 50%;
                        transform: translate(-50%, -50%);
                        left: 50%;
                        opacity: 0.5;
                    }
                }
            }
        }
    }
}


.button-group {
    margin: -15px;
    a {
        &.rn-btn {
            margin: 15px;
            transition: var(--transition);
            &.btn-small {
                padding: 13px 30px;
            }

            &.btn-large {
                padding: 18px 40px;
            }

            &.border-button {
                background: transparent;
                border: 1px solid var(--color-primary);

                &::before {
                    display: none;
                }
            }
        }
    }
}

.button-style-border-radius {
    .button-group {
        a {
            &.rn-btn {
                border-radius: 0;

                &::before {
                    border-radius: 0;
                }
            }
        }
    }
}

.button-style-underline {
    .button-group {
        &:nth-child(1) {
            margin-bottom: 30px;
        }
    }
}

.rn-btn-ulderline {
    position: relative;
    margin: 30px !important;
    transition: var(--transition);
    &::after {
        content: '';
        position: absolute;
        width: 40%;
        background: var(--color-primary);
        height: 3px;
        bottom: -3px;
        left: 0;
        transition: var(--transition);
    }
    &:hover {
        color: var(--color-primary) !important;
        &::after {
            width: 100%;
        }
    }
}


