/*-------------------------
    Section Title Styles
-------------------------- */

.section-separator{
    position: relative;
    &::after{
        position: absolute;
        max-width: 1270px;
        height: 1px;
        background: #121415;
        content: "";
        top: 0;
        left: 0;
        right: 0;
        margin: 0 auto;
    }
}
.section-flex{
    @media #{$small-mobile} {
        flex: none;
        display: block !important;
    }
}
.section-title {
    span {
        &.subtitle {
            color: #ff014f;
            text-transform: uppercase;
            letter-spacing: 5px;
            font-size: 14px;
            font-family: var(--font-secondary);
            font-weight: 500;
        }
    }
    h2 {
        &.title {
            font-size: 60px;
            font-weight: 700;
            line-height: 1.2;
            color: var(--color-lightn);
            margin-top: 15px;
            margin-bottom: 0;

            @media #{$lg-layout} {
                font-size: 38px;
                line-height: 50px;
            }

            @media #{$md-layout} {
                font-size: 40px;
                margin-top: 7px;
            }

            @media #{$sm-layout} {
                font-size: 40px;
            }
            @media #{$large-mobile} {
                text-align: center;
                margin: 0;
                font-size: 34px;
            }
        }
    }
}
