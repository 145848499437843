/*---------------------------
    Contact Area Styles 
-----------------------------*/
.footer-area {
    h4 {
        span {
            padding: 20px 23px;
            box-shadow: var(--shadow-1);
            display: inline-block;
            border-radius: 50%;
            font-size: 30px;
            line-height: 30px;
            color: var(--color-primary);
            margin-bottom: 23px;
        }
    }
    .description {
        font-size: 17px;
        color: var(--color-body);
        font-family: var(--font-primary);
        a {
            color: var(--color-body);
            transition: var(--transition);
            position: relative;
            &::before{
                position: absolute;
                content: '';
                width: 0%;
                height: 1px;
                background-color: var(--color-primary);
                left: 0;
                bottom: 3px;
                transition: var(--transition);
            }
            &:hover {
                &::before{
                    width: 100%;
                }
                color: var(--color-primary);
            }
        }
    }
    .logo {
        a {
            img {
                max-height: 100px;
            }
        }
    }
}


/*-----------------------
    Footer Style Two  
--------------------------*/
.footer-style-2 {
    .copyright {
        .description {
            color: var(--color-body);
            font-size: 18px;

            @media #{$large-mobile} {
                padding: 0 44px;
            }

            @media #{$small-mobile} {
                padding: 0 30px;
            }

            a {
                color: var(--color-body);
                transition: var(--transition);
                position: relative;
                &::after {
                    content: '';
                    position: absolute;
                    width: 70%;
                    background-color: var(--color-primary);
                    height: 0px;
                    left: 0;
                    top: 13px;
                    transition: var(--transition);
                    z-index: -1;
                }

                &:hover {
                    color: var(--color-white);

                    &::after {
                        height: 12px;
                    }
                }
            }
        }
    }

    .logo-thumbnail {
        margin-bottom: 35px;

        @media #{$large-mobile} {
            text-align: left;
        }

        img {
            max-width: 100%;
        }
    }

    .social-icone-wrapper {
        .social-share {
            @media #{$large-mobile} {
                justify-content: left;
            }

            li {
                margin: 0 25px 25px 0;

                a {
                    display: inline-block;
                    width: 60px;
                    height: 60px;
                    line-height: 60px;
                    text-align: center;
                    font-size: 14px;
                    letter-spacing: 2px;
                    text-transform: uppercase;
                    border-radius: 6px;
                    transition: var(--transition);
                    background: var(--background-color-1);
                    box-shadow: var(--shadow-1);
                    position: relative;

                    &::after {
                        content: '';
                        position: absolute;
                        transition: var(--transition);
                        width: 100%;
                        height: 100%;
                        border-radius: 6px;
                        top: 0;
                        left: 0;
                        background: linear-gradient(to right bottom, #212428, #16181c);
                        opacity: 0;
                        z-index: -1;
                    }

                    &:hover {
                        transform: translateY(-3px);

                        &::after {
                            opacity: 1;
                        }
                    }

                    svg {
                        color: var(--color-lightn);
                        transition: var(--transition);
                        stroke-width: 2;
                        position: relative;
                        z-index: 2;
                        width: 20px;
                    }
                }
            }
        }
    }

    .menu-wrapper {
        margin-left: 80px;

        @media #{$md-layout} {
            margin-left: 0;
            margin-top: 30px;
        }

        @media #{$sm-layout} {
            margin-left: 0;
        }

        @media #{$large-mobile} {
            text-align: left;
        }

        .menu-title {
            h6 {
                font-size: 18px;
                text-transform: uppercase;
                color: var(--color-primary);
                font-weight: 500;

                @media #{$large-mobile} {
                    margin-bottom: 0;
                }
            }
        }

        .menu-footer {
            padding: 0;
            margin-left: 0;

            @media #{$large-mobile} {
                margin-top: 0;
            }

            li {
                margin-bottom: 8px;
                list-style: none;

                a {
                    font-size: 17px;
                    font-weight: 400;
                    transition: var(--transition);
                    color: var(--color-lightn);
                    position: relative;

                    @media #{$sm-layout} {
                        font-size: 16px;
                    }
                    &::after {
                        content: '';
                        position: absolute;
                        width: 70%;
                        background-color: var(--color-primary);
                        height: 0px;
                        left: 0;
                        top: 13px;
                        transition: var(--transition);
                        z-index: -1;
                    }

                    &:hover {
                        color: var(--color-light);

                        &::after {
                            height: 12px;
                        }
                    }
                }
            }
        }
    }
}