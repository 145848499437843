/*----------------------------
    Mobile Menu Styles  
------------------------------*/
.popup-mobile-menu {
    z-index: 9999;
    position: fixed;
    content: "";
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.8);
    visibility: hidden;
    opacity: 0;
    transition: opacity .5s ease-out;

    .inner {
        padding: 25px 40px;
        width: 375px;
        z-index: 999;
        position: absolute;
        background: #191b1e;
        height: 100%;
        height: 100vh;
        display: flex;
        flex-direction: column;
        box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.7);
        opacity: 0;
        left: -150px;
        transition: all .5s ease-out;
        @media #{$small-mobile} {
            width: 310px;
            padding: 10px;
        }
        .menu-top {
            border-bottom: 1px solid rgba(255, 255, 255, 0.1);
            padding-bottom: 20px;
            .menu-header {
                display: flex;
                justify-content: space-between;
                align-items: center;
                .close-button {
                    .close {
                        opacity: 1;
                        width: max-content;
                        position: relative;
                        @media #{$small-mobile} {
                            right: 9px;
                        }
                        &::after{
                            position: absolute;
                            content: '';
                            padding: 22px;
                            background: var(--background-color-1);
                            left: 50%;
                            top: 50%;
                            border-radius: 50%;
                            transform: translate(-50%,-50%);
                            z-index: -1;
                            @media #{$small-mobile} {
                                padding: 20px;
                            }
                        }

                        svg {
                            color: var(--color-primary);
                            width: 23px;
                            height: auto;
                            @media #{$small-mobile} {
                                width: 20px;
                            }
                        }
                    }
                }
            }

            p {
                &.discription {
                    font-size: 16px;
                    line-height: 29px;
                    margin-top: 20px;
                    color: var(--color-lighten);
                    padding-right: 0;
                }
            }
        }
    }

    &.menu-open {
        visibility: visible;
        opacity: 1;

        .inner {
            opacity: 1;
            left: 0;
            overflow-y: auto;

            .content {
                display: flex;
                flex-direction: column;

                .primary-menu {
                    margin-top: 20px;
                    display: flex;
                    flex-direction: column;
                    padding-left: 0;
                    li {
                        display: block;
                        margin: 8px 0;
                        a {
                            color: var(--color-lightn);
                            position: relative;
                            transition: var(--transition);
                            font-size: 14px;
                            font-weight: var(--s-regular);
                            text-transform: uppercase;
                            font-family: var(--font-secondary);
                            margin: 0;
                            display: inline;
                            font-weight: 500;
                        }
                    }
                }
            }
        }
    }

}

.social-share-style-1 {
    border-top: 1px solid rgba(255, 255, 255, 0.1);
    padding-top: 20px;
    margin-top: 100px !important;
}

.social-share-style-1 .social-share li {
    margin: 0 15px 15px 0;
}

.social-share-style-1 .social-share {
    margin-right: -15px;
}

.social-share-style-1 .social-share li a {
    width: 53px;
    height: 53px;
    line-height: 53px;
    
}


.popup-mobile-menu .social-share-style-1 .social-share li a {
    box-shadow: none;
}





